import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import PrivateComponent from "../component/PrivateComponent";
import AdminPrivateComponent from "../component/AdminPrivateComponent";
import CompanyPrivateComponent from "../component/CompanyPrivateComponent";
import PageNotFound from "../component/PageNotFound";
import SalesOrder from "../container/admin/pages/salesorder/SalesOrder";
import PrivateRoute from "./PrivateRoute";
import AccessPage from "../container/admin/pages/accesspage/AccessPage";
import QrBannerPage from "../container/admin/pages/qrbannerpage/QrBannerPage";
import CompanySetup from "../container/admin/pages/companylist/CompanySetup";
import SalesVoucherEntryList from "../container/admin/pages/salesVoucher/List";
import GeneratePurchaseVoucher from "../container/admin/pages/purchaseVoucher/GeneratePurchaseVoucher";
import PurchaseVoucherEntryList from "../container/admin/pages/purchaseVoucher/List";
import GenerateCreditNoteVoucher from "../container/admin/pages/creditNote/GenerateCreditNoteVoucher";
import CreditNoteVoucherList from "../container/admin/pages/creditNote/List";
import GenerateDebitNoteVoucher from "../container/admin/pages/debitNote/GenerateDebitNoteVoucher";
import DebitNoteVoucherList from "../container/admin/pages/debitNote/List";
import StockReportWebview from "../container/admin/pages/webview/stockReport";
import SalesReportWebview from "../container/admin/pages/webview/salesReport";

import DebitNoteSalesList from "../container/admin/pages/debitNoteSales/List";
import GenerateDebitNoteSales from "../container/admin/pages/debitNoteSales/GenerateDebitNoteSales";
import Preview from "../container/admin/pages/purchaseReturn/print/Preview";
import LocationReport from "../container/admin/pages/locationReport/LocationReport";

// const PageNotFound = lazy(() => import("../component/PageNotFound"));
const ForgotPassword = lazy(() =>
  import("../container/admin/auth/forgotpassword/ForgotPassword")
);
const Login = lazy(() => import("../container/admin/auth/login/Login"));
const EditCompany = lazy(() =>
  import("../container/admin/pages/companylist/EditCompany")
);
const CompanyList = lazy(() =>
  import("../container/admin/pages/companylist/CompanyList")
);
const ViewCompany = lazy(() =>
  import("../container/admin/pages/companylist/ViewCompany")
);
const Dashboard = lazy(() =>
  import("../container/admin/pages/dashboard/Dashboard")
);
const EditProfile = lazy(() =>
  import("../container/admin/pages/editprofile/EditProfile")
);
const Home = lazy(() => import("../container/admin/pages/home/Home"));
const Report = lazy(() => import("../container/admin/pages/report/Report"));
const UnitMaster = lazy(() =>
  import("../container/admin/pages/masters/unitMaster/UnitMaster")
);
const AreaMaster = lazy(() =>
  import("../container/admin/pages/masters/areaMaster/AreaMaster")
);
const StateMaster = lazy(() =>
  import("../container/admin/pages/masters/stateMaster/StateMaster")
);
const GodwonMaster = lazy(() =>
  import("../container/admin/pages/masters/godwonMaster/GodwonMaster")
);
const NarrationMaster = lazy(() =>
  import("../container/admin/pages/masters/narrationMaster/NarrationMaster")
);
const ProcessMaster = lazy(() =>
  import("../container/admin/pages/masters/processMaster/ProcessMaster")
);
const SizeMaster = lazy(() =>
  import("../container/admin/pages/masters/sizeMaster/SizeMaster")
);
const ColourMaster = lazy(() =>
  import("../container/admin/pages/masters/colourMaster/ColourMaster")
);
const SetMaster = lazy(() =>
  import("../container/admin/pages/masters/setMaster/SetMaster")
);
const CountryMaster = lazy(() =>
  import("../container/admin/pages/masters/countryMaster/CountryMaster")
);
const CityMaster = lazy(() =>
  import("../container/admin/pages/masters/cityMaster/CityMaster")
);
const ZoneMaster = lazy(() =>
  import("../container/admin/pages/masters/zoneMaster/ZoneMaster")
);
const YearMaster = lazy(() =>
  import("../container/admin/pages/masters/yearMaster/YearMaster")
);
const ProductGroupMaster = lazy(() =>
  import(
    "../container/admin/pages/masters/productGroupMaster/ProductGroupMaster"
  )
);
const HSNMaster = lazy(() =>
  import("../container/admin/pages/masters/hsnMaster/HSNMaster")
);
const ProductCategory = lazy(() =>
  import("../container/admin/pages/masters/productCategory/ProductCategory")
);
const ProductMaster = lazy(() =>
  import("../container/admin/pages/masters/productMaster/ProductMaster")
);
const OpeningStock = lazy(() =>
  import("../container/admin/pages/masters/openingStock/OpeningStockMaster")
);
const FinishStock = lazy(() =>
  import("../container/admin/pages/masters/finishStock/FinishStock")
);
const TaxMaster = lazy(() =>
  import("../container/admin/pages/masters/taxMaster/TaxMaster")
);
const TaxCategoryMaster = lazy(() =>
  import("../container/admin/pages/masters/taxCategoryMaster/TaxCategoryMaster")
);
const AccountGroupMaster = lazy(() =>
  import(
    "../container/admin/pages/masters/accountGroupMaster/AccountGroupMaster"
  )
);
const CurrencyMaster = lazy(() =>
  import("../container/admin/pages/masters/currencyMaster/CurrencyMaster")
);
const ItemSizePriceListUp = lazy(() =>
  import(
    "../container/admin/pages/masters/itemSizePriceList/ItemSizePriceListUp"
  )
);
const ItemSizePriceListDown = lazy(() =>
  import("../container/admin/pages/masters/itemSizePriceList/ItemSizePriceDown")
);
const ItemSizePriceListUpParty = lazy(() =>
  import(
    "../container/admin/pages/masters/itemSizePriceList/ItemSizePriceListUpParty"
  )
);
const AccountMaster = lazy(() =>
  import("../container/admin/pages/masters/accountMaster/AccountMaster")
);
const AccountLedger = lazy(() =>
  import("../container/admin/pages/masters/accountMaster/AddAccountLedger")
);
const ViewAccountLedger = lazy(() =>
  import("../container/admin/pages/masters/accountMaster/ViewAccountLedger")
);
const DepartmentMaster = lazy(() =>
  import("../container/admin/pages/masters/departmentMaster/DepartmentMaster")
);
const CostGroupMaster = lazy(() =>
  import("../container/admin/pages/masters/costGroupMaster/CostGroupMaster")
);
const FixedAssetsMaster = lazy(() =>
  import("../container/admin/pages/masters/fixedAssetsMaster/FixedAssetsMaster")
);
const OpeningBalance = lazy(() =>
  import("../container/admin/pages/masters/openingBalance/OpeningBalance")
);

const SalesOrderList = lazy(() =>
  import("../container/admin/pages/salesorder/SalesList")
);

const PreviewSalesOrder = lazy(() =>
  import("../container/admin/pages/salesorder/Preview")
);

const SalesOrderReport = lazy(() =>
  import("../container/admin/pages/salesorder/reports/report")
);

const PackingChallanReport = lazy(() =>
  import("../container/admin/pages/packingSlip/reports/report")
);

const SalesEntryReport = lazy(() =>
  import("../container/admin/pages/salesEntry/reports/report")
);

const SalesReturnReport = lazy(() =>
  import("../container/admin/pages/salesReturn/reports/report")
);

const StockReport = lazy(() =>
  import("../container/admin/pages/finishEntry/reports/report")
);

const LedgerReport = lazy(() =>
  import("../container/admin/pages/finishEntry/reports/LedgerReport")
);

const CommissionReport = lazy(() =>
  import("../container/admin/pages/commissionReport/CommissionReport")
);

const JournalVoucherReport = lazy(() =>
  import("../container/admin/pages/finishEntry/reports/JournalVoucherReport")
);

const AddRole = lazy(() => import("../container/admin/pages/role/AddRole"));
const RoleList = lazy(() => import("../container/admin/pages/role/RoleList"));

const CreateBarcode = lazy(() =>
  import("../container/admin/pages/barcode/CreateBarcode")
);
const BarcodeList = lazy(() =>
  import("../container/admin/pages/barcode/BarcodeList")
);

const GenerateFinishEntry = lazy(() =>
  import("../container/admin/pages/finishEntry/generateFinishEntry")
);

const FinishEntryList = lazy(() =>
  import("../container/admin/pages/finishEntry/FinishEntryList")
);
const GeneratePackingSlip = lazy(() =>
  import("../container/admin/pages/packingSlip/GeneratePackingSlip")
);
const PackingSlipList = lazy(() =>
  import("../container/admin/pages/packingSlip/PackingSLipList")
);

const DeliveryChallan = lazy(() =>
  import("../container/admin/pages/packingSlip/DeliveryChallan")
);

const PackingVoucher = lazy(() =>
  import("../container/admin/pages/packingSlip/Voucher")
);
const PackingVoucherWithoutMRP = lazy(() =>
  import("../container/admin/pages/packingSlip/VoucherWithoutMrp")
);

const GenerateSalesEntry = lazy(() =>
  import("../container/admin/pages/salesEntry/GenerateSalesEntry")
);

const SalesEntryList = lazy(() =>
  import("../container/admin/pages/salesEntry/List")
);
const PurchasePrint = lazy(() =>
  import("../container/admin/pages/purchaseVoucher/Print")
);
const CreditNotPrint = lazy(() =>
  import("../container/admin/pages/creditNote/Print")
);
const DebitNotPurchesPrint = lazy(() =>
  import("../container/admin/pages/debitNote/Print")
);

const GenerateOtherSalesEntry = lazy(() =>
  import("../container/admin/pages/otherSalesEntry/GenerateSalesEntry")
);

const OtherSalesEntryList = lazy(() =>
  import("../container/admin/pages/otherSalesEntry/List")
);

const GenerateSalesReturn = lazy(() =>
  import("../container/admin/pages/salesReturn/GenerateSalesReturn")
);

const SalesReturnList = lazy(() =>
  import("../container/admin/pages/salesReturn/List")
);

const Invoice1 = lazy(() =>
  import("../container/admin/pages/salesEntry/invoice/invoice1")
);
const Invoice2 = lazy(() =>
  import("../container/admin/pages/salesEntry/invoice/invoice2")
);
const WhatsappInvoice2 = lazy(() =>
  import("../container/admin/pages/salesEntry/invoice/whatsappInvoice2")
);
const SalesInvoicePrint = lazy(() =>
  import("../container/admin/pages/salesVoucher/print/Print")
);
const DebitNotSalesPrint = lazy(() =>
  import("../container/admin/pages/debitNoteSales/Print")
);
const Invoice3 = lazy(() =>
  import("../container/admin/pages/salesEntry/invoice/invoice3")
);

const OtherSalesInvoice1 = lazy(() =>
  import("../container/admin/pages/otherSalesEntry/invoice/invoice1")
);
const OtherSalesInvoice2 = lazy(() =>
  import("../container/admin/pages/otherSalesEntry/invoice/invoice2")
);
const OtherSalesInvoice3 = lazy(() =>
  import("../container/admin/pages/otherSalesEntry/invoice/invoice3")
);

const SalesReturnPreview = lazy(() =>
  import("../container/admin/pages/salesReturn/preview/preview")
);
const SalesReturnPreview2 = lazy(() =>
  import("../container/admin/pages/salesReturn/preview/preview2")
);
const WhatsappSalesReturnPreview2 = lazy(() =>
  import("../container/admin/pages/salesReturn/preview/whatsappPreview2")
);
const SalesReturnPreview3 = lazy(() =>
  import("../container/admin/pages/salesReturn/preview/preview3")
);

// Purchase modules
const GeneratePurchaseOrder = lazy(() =>
  import("../container/admin/pages/purchaseOrder/GeneratePurchaseOrder")
);
const PurchaseOrderList = lazy(() =>
  import("../container/admin/pages/purchaseOrder/List")
);

const PurchaseOrderPrint = lazy(() =>
  import("../container/admin/pages/purchaseOrder/print/Preview")
);

const PurchaseOrderReport = lazy(() =>
  import("../container/admin/pages/purchaseOrder/reports/report")
);

// GRN modules
const GenerateGrn = lazy(() =>
  import("../container/admin/pages/grn/GenerateGrn")
);
const GrnList = lazy(() => import("../container/admin/pages/grn/GrnList"));
const GrnPrint = lazy(() =>
  import("../container/admin/pages/grn/print/Preview")
);
const GrnReport = lazy(() =>
  import("../container/admin/pages/grn/reports/report")
);

// Purchase Entry
const GeneratePurchaseEntry = lazy(() =>
  import("../container/admin/pages/purchaseEntry/GeneratePurchaseEntry")
);

const PurchaseEntryList = lazy(() =>
  import("../container/admin/pages/purchaseEntry/List")
);

const PurchaseEntryReport = lazy(() =>
  import("../container/admin/pages/purchaseEntry/reports/report")
);

// Purchase Entry Size Wise
const GeneratePurchaseEntrySizeWise = lazy(() =>
  import(
    "../container/admin/pages/purchaseEntrySizeWise/GeneratePurchaseEntrySize"
  )
);

const PurchaseEntrySizeList = lazy(() =>
  import("../container/admin/pages/purchaseEntrySizeWise/List")
);

// Purchase Return
const GeneratePurchaseReturn = lazy(() =>
  import("../container/admin/pages/purchaseReturn/GeneratePurchaseReturn")
);

const PurchaseReturnList = lazy(() =>
  import("../container/admin/pages/purchaseReturn/List")
);

const PurchaseReturnReport = lazy(() =>
  import("../container/admin/pages/purchaseReturn/reports/report")
);

// Material Stock Report
const MaterialStockReport = lazy(() =>
  import("../container/admin/pages/materialStockReport/report")
);

// Manage Users
const ManageUsers = lazy(() =>
  import("../container/admin/pages/users/UserList")
);

// Journal Voucher
const GenerateJV = lazy(() =>
  import("../container/admin/pages/journalVoucher/JournalVoucher")
);
const JVList = lazy(() =>
  import("../container/admin/pages/journalVoucher/JVList")
);

const GenerateSalesVoucher = lazy(() =>
  import("../container/admin/pages/salesVoucher/GenerateSalesVoucher")
);
// const StockReportWebview = lazy(() =>
//   import("../container/admin/pages/webview/stockReport")
// );

const PreviewJV = lazy(() =>
  import("../container/admin/pages/journalVoucher/print/Preview")
);

const GenerateSurplusWastage = lazy(() =>
  import("../container/admin/pages/surplusWastageEntry/generateSurplusWastage")
);

const SurplusWastageList = lazy(() =>
  import("../container/admin/pages/surplusWastageEntry/List")
);

const GenerateStockTransfer = lazy(() =>
  import("../container/admin/pages/stockTransfer/generateStockTransfer")
);

const StockTransferList = lazy(() =>
  import("../container/admin/pages/stockTransfer/List")
);

// Cash/Bank Entry
const GenerateCashBank = lazy(() =>
  import("../container/admin/pages/cashBank/GenrateCashBank")
);
const CashBankList = lazy(() =>
  import("../container/admin/pages/cashBank/List")
);

const LrUpdation = lazy(() =>
  import("../container/admin/pages/lrUpdation/LrUpdation")
);

const Print = lazy(() => import("../container/admin/pages/cashBank/Print"));

// Advance Adjustment
const AdvanceAdjustment = lazy(() =>
  import("../container/admin/pages/advanceAdjustment/advanceAdjust")
);

// Tds Entry
const GenerateTdsEntry = lazy(() =>
  import("../container/admin/pages/tdsEntry/generateTdsEntry")
);
const TdsEntryList = lazy(() =>
  import("../container/admin/pages/tdsEntry/List")
);

const TdsReport = lazy(() =>
  import("../container/admin/pages/tdsReport/report")
);

//Account Reoprt
const AccountReport = lazy(() =>
  import("../container/admin/pages/accountReport/report")
);

//Product Master Reoprt
const ProductMasterReport = lazy(() =>
  import("../container/admin/pages/productMasterReport/report")
);
const OutstandingReport = lazy(() =>
  import("../container/admin/pages/outstandingReport/OutstandingReport")
);

const CashBankBookReport = lazy(() =>
  import("../container/admin/pages/cashBank/reports/bankbook")
);
const CashBankRegisterReport = lazy(() =>
  import("../container/admin/pages/cashBank/reports/cashBankRegisterReport")
);

const Routers = () => {
  const { permissions } = useSelector((state) => {
    return state.ERPAdminReducer;
  });
  return (
    <Suspense
      fallback={
        <LoadingOverlay active={true} spinner text="Loading your content..." />
      }
    >
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route index path="/" element={<Login />} />
          <Route path="/:key" element={<Login />} />
          <Route path="/forgot-password/:key" element={<ForgotPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateComponent />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="home" element={<Home />} />
              <Route path="report" element={<Report />} />
              <Route path="edit-profile" element={<EditProfile />} />
              <Route element={<AdminPrivateComponent />}>
                <Route path="client-list" element={<CompanyList />} />
                <Route path="company-setup" element={<CompanySetup />} />
                <Route path="edit-client/:id" element={<EditCompany />} />
                <Route path="view-client/:id" element={<ViewCompany />} />
                <Route path="country-master" element={<CountryMaster />} />
                <Route path="state-master" element={<StateMaster />} />
                <Route path="unit-master" element={<UnitMaster />} />
                <Route path="city-master" element={<CityMaster />} />
                <Route path="zone-master" element={<ZoneMaster />} />
                <Route path="year-master" element={<YearMaster />} />
              </Route>

              <Route element={<CompanyPrivateComponent />}>
                <Route
                  path="roles"
                  element={
                    permissions?.includes("role_master_view") ? (
                      <RoleList />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="add-role"
                  element={
                    permissions?.includes("role_master_add") ? (
                      <AddRole />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="edit-role/:id"
                  element={
                    permissions?.includes("role_master_edit") ? (
                      <AddRole />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="fixed-assets"
                  element={
                    permissions?.includes("fixed_assets_master_view") ? (
                      <FixedAssetsMaster permission="asset_view" />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                {/* <Route element={<PrivateRoute
                    element={<Route
                      path="fixed-assets"
                      element={<FixedAssetsMaster />}
                    />}
                    permissionTag="fixed_assets_master_view"
                  />}/> */}
                <Route
                  path="account-group-master"
                  element={
                    permissions?.includes("account_group_master_view") ? (
                      <AccountGroupMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="department-master"
                  element={
                    permissions?.includes("department_master_view") ? (
                      <DepartmentMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="cost-group-master"
                  element={
                    permissions?.includes("cost_group_master_view") ? (
                      <CostGroupMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="area-master"
                  element={
                    permissions?.includes("area_master_view") ? (
                      <AreaMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="godwon-master"
                  element={
                    permissions?.includes("godown_master_view") ? (
                      <GodwonMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="narration-master"
                  element={
                    permissions?.includes("narration_master_view") ? (
                      <NarrationMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="process-master"
                  element={
                    permissions?.includes("process_master_view") ? (
                      <ProcessMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="size-master"
                  element={
                    permissions?.includes("size_master_view") ? (
                      <SizeMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="colour-master"
                  element={
                    permissions?.includes("colour_master_view") ? (
                      <ColourMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="set-master"
                  element={
                    permissions?.includes("set_master_view") ? (
                      <SetMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="prouduct-group-master"
                  element={
                    permissions?.includes("product_group_master_view") ? (
                      <ProductGroupMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="HSN-master"
                  element={
                    permissions?.includes("hsn_master_view") ? (
                      <HSNMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="currency-master"
                  element={
                    // permissions?.includes("area_master_view") ? (
                    <CurrencyMaster />
                    // ) : (
                    //   <AccessPage />
                    // )
                  }
                />
                <Route
                  path="product-category-master"
                  element={
                    permissions?.includes("product_category_master_view") ? (
                      <ProductCategory />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="product-master"
                  element={
                    permissions?.includes("product_master_view") ? (
                      <ProductMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="opening-stock"
                  element={
                    permissions?.includes("opening_stock_view") ? (
                      <OpeningStock />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="finish-stock"
                  element={
                    permissions?.includes("finish_stock_view") ? (
                      <FinishStock />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="item-size-price-list-up"
                  element={
                    permissions?.includes("item_size_price_up_view") ? (
                      <ItemSizePriceListUp />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="item-size-price-list-down"
                  element={
                    permissions?.includes("item_size_price_down_view") ? (
                      <ItemSizePriceListDown />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="item-size-price-list-party"
                  element={<ItemSizePriceListUpParty />}
                />
                <Route
                  path="tax-master"
                  element={
                    permissions?.includes("sales_expenses_tax_master_view") ? (
                      <TaxMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="tax-category-master"
                  element={
                    permissions?.includes("tax_category_master_view") ? (
                      <TaxCategoryMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="account-master"
                  element={
                    permissions?.includes("account_master_view") ? (
                      <AccountMaster />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="account-master/account-ledger"
                  element={
                    permissions?.includes("account_master_add") ? (
                      <AccountLedger />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="account-master/account-ledger/:id"
                  element={
                    permissions?.includes("account_master_edit") ? (
                      <AccountLedger />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="account-master/view-account-ledger/:id"
                  element={
                    permissions?.includes("account_master_view") ? (
                      <ViewAccountLedger />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="opening-balance"
                  element={
                    permissions?.includes("opening_balance_view") ? (
                      <OpeningBalance />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="salesorder"
                  element={
                    permissions?.includes("sales_order_module_add") ? (
                      <SalesOrder />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="sales-order-list"
                  element={
                    permissions?.includes("sales_order_module_view") ? (
                      <SalesOrderList />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="preview-sales-order/:id"
                  element={
                    permissions?.includes("sales_order_module_view") ? (
                      <PreviewSalesOrder />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="edit-order/:id"
                  element={
                    permissions?.includes("sales_order_module_edit") ? (
                      <SalesOrder />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
                <Route
                  path="sales-order-report"
                  element={
                    permissions?.includes("sales_order_report_view") ? (
                      <SalesOrderReport />
                    ) : (
                      <AccessPage />
                    )
                  }
                />
              </Route>
              <Route
                path="generate-barcode"
                element={
                  permissions?.includes("barcode_master_add") ? (
                    <CreateBarcode />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="barcode-list"
                element={
                  permissions?.includes("barcode_master_view") ? (
                    <BarcodeList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-barcode/:id"
                element={
                  permissions?.includes("barcode_master_edit") ? (
                    <CreateBarcode />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-finish-entry"
                element={
                  permissions?.includes("finish_entry_add") ? (
                    <GenerateFinishEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="finish-entry-list"
                element={
                  permissions?.includes("finish_entry_view") ? (
                    <FinishEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-finish-entry/:id"
                element={
                  permissions?.includes("finish_entry_edit") ? (
                    <GenerateFinishEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="stock-report"
                element={
                  permissions?.includes("reports_view") ? (
                    <StockReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route path="ledger-report" element={<LedgerReport />} />
              <Route
                path="jounral-voucher-report"
                element={<JournalVoucherReport />}
              />

              <Route path="accesspage" element={<AccessPage />} />
              <Route
                path="generate-packing-slip"
                element={
                  permissions?.includes("packing_challan_entry_add") ? (
                    <GeneratePackingSlip />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="packing-slip-list"
                element={
                  permissions?.includes("packing_challan_entry_view") ? (
                    <PackingSlipList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="packing-slip-report"
                element={
                  permissions?.includes("packing_challan_report_view") ? (
                    <PackingChallanReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="delivery-challan/:id"
                element={
                  permissions?.includes("packing_challan_entry_view") ? (
                    <DeliveryChallan />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="packing-voucher/:id"
                element={
                  permissions?.includes("packing_challan_entry_view") ? (
                    <PackingVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="packing-voucher-without-mrp/:id"
                element={
                  permissions?.includes("packing_challan_entry_view") ? (
                    <PackingVoucherWithoutMRP />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route path="qrbannerpage" element={<QrBannerPage />} />
              <Route
                path="edit-packing-slip/:id"
                element={
                  permissions?.includes("packing_challan_entry_edit") ? (
                    <GeneratePackingSlip />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-sales-entry"
                element={
                  permissions?.includes("sales_entry_add") ? (
                    <GenerateSalesEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="sales-entry-list"
                element={
                  permissions?.includes("sales_entry_view") ? (
                    <SalesEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-sales-entry/:id"
                element={
                  permissions?.includes("sales_entry_edit") ? (
                    <GenerateSalesEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="invoice1/:id"
                element={
                  permissions?.includes("sales_entry_view") ? (
                    <Invoice1 />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="invoice2/:id"
                element={
                  permissions?.includes("sales_entry_view") ? (
                    <Invoice2 />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="sales-invoice-print/:id"
                element={true ? <SalesInvoicePrint /> : <AccessPage />}
              />
              <Route
                path="debit-note-sales-print/:id"
                element={true ? <DebitNotSalesPrint /> : <AccessPage />}
              />
              <Route
                path="purches-print/:id"
                element={true ? <PurchasePrint /> : <AccessPage />}
              />
              <Route
                path="credit-not-print/:id"
                element={true ? <CreditNotPrint /> : <AccessPage />}
              />
              <Route
                path="debit-purches-not-print/:id"
                element={true ? <DebitNotPurchesPrint /> : <AccessPage />}
              />
              <Route
                path="invoice3/:id"
                element={
                  permissions?.includes("sales_entry_view") ? (
                    <Invoice3 />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="sales-entry-report"
                element={
                  permissions?.includes("sales_entry_report_view") ? (
                    <SalesEntryReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-other-sales-entry"
                element={
                  permissions?.includes("other_sales_entry_add") ? (
                    <GenerateOtherSalesEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="other-sales-entry-list"
                element={
                  permissions?.includes("other_sales_entry_view") ? (
                    <OtherSalesEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-other-sales-entry/:id"
                element={
                  permissions?.includes("other_sales_entry_edit") ? (
                    <GenerateOtherSalesEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="other-sales-invoice1/:id"
                element={
                  permissions?.includes("other_sales_entry_view") ? (
                    <OtherSalesInvoice1 />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="other-sales-invoice2/:id"
                element={
                  permissions?.includes("other_sales_entry_view") ? (
                    <OtherSalesInvoice2 />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="other-sales-invoice3/:id"
                element={
                  permissions?.includes("other_sales_entry_view") ? (
                    <OtherSalesInvoice3 />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="sales-return-list"
                element={
                  permissions?.includes("sales_return_view") ? (
                    <SalesReturnList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-sales-return"
                element={
                  permissions?.includes("sales_return_add") ? (
                    <GenerateSalesReturn />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-sales-return/:id"
                element={
                  permissions?.includes("sales_return_edit") ? (
                    <GenerateSalesReturn />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="sales-return/preview/:id"
                element={
                  permissions?.includes("sales_return_view") ? (
                    <SalesReturnPreview />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="sales-return/preview2/:id"
                element={
                  permissions?.includes("sales_return_view") ? (
                    <SalesReturnPreview2 />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="sales-return/preview3/:id"
                element={
                  permissions?.includes("sales_return_view") ? (
                    <SalesReturnPreview3 />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="sales-return-report"
                element={
                  permissions?.includes("sales_return_report_view") ? (
                    <SalesReturnReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-Order"
                element={
                  permissions?.includes("purchase_order_module_view") ? (
                    <PurchaseOrderList />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-purchase-Order"
                element={
                  permissions?.includes("purchase_order_module_add") ? (
                    <GeneratePurchaseOrder />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="edit-purchase-Order/:id"
                element={
                  permissions?.includes("purchase_order_module_edit") ? (
                    <GeneratePurchaseOrder />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-order-print/:id"
                element={
                  permissions?.includes("purchase_order_module_view") ? (
                    <PurchaseOrderPrint />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-order-report"
                element={
                  permissions?.includes("purchase_order_report_view") ? (
                    <PurchaseOrderReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-grn"
                element={
                  permissions?.includes("grn_entry_add") ? (
                    <GenerateGrn />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="grn-list"
                element={
                  permissions?.includes("grn_entry_view") ? (
                    <GrnList />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="edit-grn/:id"
                element={
                  permissions?.includes("grn_entry_edit") ? (
                    <GenerateGrn />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="grn-print/:id"
                element={
                  permissions?.includes("grn_entry_view") ? (
                    <GrnPrint />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="grn-report"
                element={
                  permissions?.includes("grn_report_view") ? (
                    <GrnReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-purchase-entry"
                element={
                  permissions?.includes("purchase_entry_add") ? (
                    <GeneratePurchaseEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-entry-list"
                element={
                  permissions?.includes("purchase_entry_view") ? (
                    <PurchaseEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="edit-purchase-entry/:id"
                element={
                  permissions?.includes("purchase_entry_edit") ? (
                    <GeneratePurchaseEntry />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-entry-report"
                element={
                  permissions?.includes("purchase_entry_report_view") ? (
                    <PurchaseEntryReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-purchase-entry-size-wise"
                element={
                  permissions?.includes("purchase_entry_size_wise_add") ? (
                    <GeneratePurchaseEntrySizeWise />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="purchase-entry-size-wise-list"
                element={
                  permissions?.includes("purchase_entry_size_wise_view") ? (
                    <PurchaseEntrySizeList />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="edit-purchase-entry-size-wise/:id"
                element={
                  permissions?.includes("purchase_entry_size_wise_edit") ? (
                    <GeneratePurchaseEntrySizeWise />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-purchase-return"
                element={
                  permissions?.includes("purchase_return_add") ? (
                    <GeneratePurchaseReturn />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-return-list"
                element={
                  permissions?.includes("purchase_return_view") ? (
                    <PurchaseReturnList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="purchase-return-print/:id"
                element={
                  permissions?.includes("purchase_return_view") ? (
                    <Preview />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="edit-purchase-return/:id"
                element={
                  permissions?.includes("purchase_return_edit") ? (
                    <GeneratePurchaseReturn />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="purchase-return-report"
                element={
                  permissions?.includes("purchase_return_report_view") ? (
                    <PurchaseReturnReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="material-stock-report"
                element={
                  permissions?.includes("material_stock_report_view") ? (
                    <MaterialStockReport />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="manage-users"
                element={
                  permissions?.includes("manage_users_view") ? (
                    <ManageUsers />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="sales-voucher-entry-list"
                element={
                  permissions?.includes("voucher_entry_sales_view") ? (
                    <SalesVoucherEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-sales-voucher"
                element={
                  permissions?.includes("voucher_entry_sales_add") ? (
                    <GenerateSalesVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-sales-voucher/:id"
                element={
                  permissions?.includes("voucher_entry_sales_edit") ? (
                    <GenerateSalesVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="purchase-voucher-entry-list"
                element={
                  permissions?.includes("voucher_entry_purchase_view") ? (
                    <PurchaseVoucherEntryList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-purchase-voucher"
                element={
                  permissions?.includes("voucher_entry_purchase_add") ? (
                    <GeneratePurchaseVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-purchase-voucher/:id"
                element={
                  permissions?.includes("voucher_entry_purchase_edit") ? (
                    <GeneratePurchaseVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="credit-note-entry-list"
                element={
                  permissions?.includes("voucher_entry_credit_note_view") ? (
                    <CreditNoteVoucherList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-credite-note-voucher"
                element={
                  permissions?.includes("voucher_entry_credit_note_add") ? (
                    <GenerateCreditNoteVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-credite-note-voucher/:id"
                element={
                  permissions?.includes("voucher_entry_credit_note_edit") ? (
                    <GenerateCreditNoteVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="debit-note-entry-list"
                element={
                  permissions?.includes("voucher_entry_credit_note_view") ? (
                    <DebitNoteVoucherList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-debit-note-voucher"
                element={
                  permissions?.includes("voucher_entry_credit_note_add") ? (
                    <GenerateDebitNoteVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-debit-note-voucher/:id"
                element={
                  permissions?.includes("voucher_entry_credit_note_edit") ? (
                    <GenerateDebitNoteVoucher />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="debit-note-sales-list"
                element={
                  permissions?.includes("voucher_entry_credit_note_view") ? (
                    <DebitNoteSalesList />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="generate-debit-note-sales-voucher"
                element={
                  permissions?.includes("voucher_entry_credit_note_add") ? (
                    <GenerateDebitNoteSales />
                  ) : (
                    <AccessPage />
                  )
                }
              />
              <Route
                path="edit-debit-note-sales-voucher/:id"
                element={
                  permissions?.includes("voucher_entry_credit_note_edit") ? (
                    <GenerateDebitNoteSales />
                  ) : (
                    <AccessPage />
                  )
                }
              />

              <Route
                path="generate-surplus-wastage"
                element={<GenerateSurplusWastage />}
              />

              <Route
                path="surplus-wastage-list"
                element={<SurplusWastageList />}
              />

              <Route
                path="edit-surplus-wastage/:id"
                element={<GenerateSurplusWastage />}
              />

              <Route
                path="generate-stock-transfer"
                element={<GenerateStockTransfer />}
              />

              <Route
                path="stock-transfer-list"
                element={<StockTransferList />}
              />

              <Route
                path="edit-stock-transfer/:id"
                element={<GenerateStockTransfer />}
              />

              <Route path="jv-list" element={<JVList />} />
              <Route path="generate-jv" element={<GenerateJV />} />
              <Route path="edit-jv" element={<GenerateJV />} />
              <Route path="preview-journal-voucher" element={<PreviewJV />} />

              <Route path="generate-cashbank" element={<GenerateCashBank />} />
              <Route path="cashbank-list" element={<CashBankList />} />

              <Route path="edit-cashbank/:id" element={<GenerateCashBank />} />
              <Route path="lr-update" element={<LrUpdation />} />
              <Route
                path="cash-bank-print/:id"
                element={true ? <Print /> : <AccessPage />}
              />
              <Route
                path="advance-adjustment"
                element={<AdvanceAdjustment />}
              />
              <Route path="generate-tds-entry" element={<GenerateTdsEntry />} />
              <Route path="tds-entry-list" element={<TdsEntryList />} />
              <Route path="edit-tds-entry/:id" element={<GenerateTdsEntry />} />
              <Route path="tds-report" element={<TdsReport />} />
              <Route path="account-report" element={<AccountReport />} />
              <Route path="commission-report" element={<CommissionReport />} />
              <Route
                path="product-master-report"
                element={<ProductMasterReport />}
              />
              <Route
                path="outstanding-report"
                element={<OutstandingReport />}
              />
              <Route path="location-report" element={<LocationReport />} />
              <Route
                path="location-report/:id"
                element={<LocationReport />}
              />
              <Route
                path="cashbank-book-report"
                element={<CashBankBookReport />}
              />
              <Route
                path="cashbank-register-report"
                element={<CashBankRegisterReport />}
              />
            </Route>
          </Route>
          <Route
            path="whatsappInvoice2/:id/:companyId"
            element={
              <WhatsappInvoice2 />
            }
          />
          <Route
            path="sales-return/whatsapp-preview2/:id/:companyId"
            element={
              <WhatsappSalesReturnPreview2 />
            }
          />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route
            index
            path="/webview-stock-report/:companyId/:fromDate"
            element={<StockReportWebview />}
          />
          <Route
            index
            path="/webview-sales-report/:companyId/:selectedYearStart/:selectedYearEnd"
            element={<SalesReportWebview />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Routers;
