import React, { useState, useEffect, useRef } from "react";
import Index from "../../../../component/Index";
import ContainerIndex from "../../../Index";
import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import * as XLSX from "xlsx/xlsx.mjs";
import { getLocationReportDataApiHandler } from "../../../../services/AdminIndex";
import LoadingSpinner from "../../../../component/LoadingSpinner";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";

const Item = styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function LocationReport() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = Index.useForm();

  const filterRef = useRef();
  const printRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState(1);
  const { loading, token, selectedYearStart, selectedYearEnd } =
    Index.useSelector((state) => {
      return state.ERPAdminReducer;
    });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "padding-10",
  });

  const handleScrollToTop = (ref) => {
    filterRef.current.scrollIntoView();
  };

  useEffect(() => {
    setValue("fromDate", dayjs(selectedYearStart));
    setValue("toDate", dayjs(selectedYearEnd));
    const data = {};
    data.id = id;
    data.fromDate = dayjs(dayjs(selectedYearStart)).format("YYYY-MM-DD");
    data.toDate = dayjs(selectedYearEnd).format("YYYY-MM-DD");
    try {
      getLocationReportDataApiHandler(token, data).then(async (response) => {
        if (response.status === 201 || response.status === 200) {
          setReportData(response?.data);
          dispatch({ type: "LOADER_END" });
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [selectedYearStart]);

  const exportToExcel = () => {
    // Convert table to sheet
    const ws = XLSX?.utils?.table_to_sheet(printRef.current);
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    // return false;
    data.splice(2, 0, []);
    const newWs = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX?.utils?.book_new();
    XLSX?.utils?.book_append_sheet(wb, newWs, "Location Report");
    XLSX.writeFile(wb, "location-report.xlsx");
  };

  const submit = (data) => {
    data.id = id;
    data.fromDate = dayjs(data?.fromDate).format("YYYY-MM-DD");
    data.toDate = dayjs(data?.toDate).format("YYYY-MM-DD");
    try {
      getLocationReportDataApiHandler(token, data).then(async (response) => {
        if (response.status === 201 || response.status === 200) {
          setReportData(response?.data);
          dispatch({ type: "LOADER_END" });
        } else {
          dispatch({ type: "ADMIN_ERROR_HANDLER", payload: data });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const reportContent = (reportData) => {
    return (
      <Index.Box className="card-spacing">
        <Index.Box className="page-table-main">
          <TableContainer
            component={Paper}
            className="table-container summary-table-container"
          >
            <div ref={printRef}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table summary-table"
              >
                <TableHead className="table-head">
                  <TableRow className="table-row">
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Party Name
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Date & Time
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="table-cell-th"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="table-body">
                  {reportData?.length ? (
                    reportData
                      ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.map((row, index) => {
                        return (
                          <TableRow className="table-row">
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.userName}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {row?.sundryDebtors
                                ? row?.sundryDebtors
                                : row?.otherParty}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              className="table-cell-td"
                            >
                              {moment(row?.createdAt).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                            </TableCell>
                            <TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              <Index.Box className="userdata-btn-flex">
                                <Tooltip title="Location" placement="top">
                                  <Index.Link
                                    className="table-btns table-edit-btn"
                                    disableRipple
                                    target="_blank"
                                    to={`https://maps.google.com?q=${row?.latitude},${row?.longitude}`}
                                  >
                                    <img
                                      src={Index.Svg.location}
                                      className="table-icons"
                                    ></img>
                                  </Index.Link>
                                </Tooltip>
                              </Index.Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow className="table-row">
                      <TableCell
                        component="td"
                        scope="row"
                        className="table-cell-td text-center"
                        colSpan={"100%"}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
          <Index.Box className="pagination-table-design">
            <Index.Stack spacing={2} className="stack-pagination">
              <Index.Pagination
                count={-Math.floor(-(reportData?.length / 10))}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                className="stack-pagination-list"
              />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    );
  };

  if (loading) {
    <LoadingSpinner />;
  } else {
    return (
      <div>
        <Index.Box className="main_content">
          <Index.Box className="title_top_fix">
            <Index.Box className="flex_justify">
              <Index.Typography variant="h4" component="h4">
                Location Reports
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="web_main_content">
            <Index.Box className="over_text_scroll">
              <Index.Box className="admin-dashboard-list-row">
                <Index.Box
                  className="report-main-box report-main-box-set"
                  ref={filterRef}
                >
                  <form
                    className="form-content"
                    onSubmit={handleSubmit(submit)}
                  >
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group date-of-purchase">
                                <Index.FormHelperText className="label_control">
                                  From Date
                                </Index.FormHelperText>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <Controller
                                      control={control}
                                      {...register("fromDate")}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <MobileDatePicker
                                          // disableFuture={true}
                                          className="custom_form_control"
                                          format="DD/MM/YYYY"
                                          value={value}
                                          minDate={dayjs(selectedYearStart)}
                                          maxDate={dayjs(selectedYearEnd)}
                                          onChange={(newValue) => {
                                            setValue("fromDate", newValue);
                                            setValue("toDate", null);
                                          }}
                                          error={Boolean(errors.fromDate)}
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Item className="grid-item">
                            <Index.Box className="input-box">
                              <Index.Box className="form-group date-of-purchase">
                                <Index.FormHelperText className="label_control">
                                  To Date
                                </Index.FormHelperText>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <Controller
                                      control={control}
                                      {...register("toDate")}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <MobileDatePicker
                                          // disableFuture={true}
                                          className="custom_form_control"
                                          format="DD/MM/YYYY"
                                          value={value}
                                          minDate={
                                            watch("fromDate")
                                              ? watch("fromDate")
                                              : dayjs(selectedYearStart)
                                          }
                                          maxDate={dayjs(selectedYearEnd)}
                                          onChange={(newValue) => {
                                            setValue("toDate", newValue);
                                          }}
                                          error={Boolean(errors.toDate)}
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-flex-end ">
                        <Index.Box className="btn_linear">
                          <Index.Button
                            variant=""
                            className="linear-btn-main"
                            type="submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                  {/* {reportData?.length ? (
                    <Index.Box className="btn-flex-end">
                      <Index.Box className="secondary-btn-main">
                        <ContainerIndex.LinearButton
                          btnLabel="Print"
                          className="linear-btn-main"
                          onClick={() => handlePrint()}
                        ></ContainerIndex.LinearButton>
                      </Index.Box>
                      <ContainerIndex.LinearButton
                        btnLabel="Excel"
                        className="linear-btn-main"
                        onClick={() => exportToExcel()}
                      ></ContainerIndex.LinearButton>
                    </Index.Box>
                  ) : (
                    <></>
                  )} */}
                  {reportContent(reportData)}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {reportData?.length ? (
          <Index.Box className="btn-flex-end">
            <ContainerIndex.LinearButton
              btnLabel="Go To Top"
              className="linear-btn-main"
              onClick={() => handleScrollToTop()}
            ></ContainerIndex.LinearButton>
            {/* <ContainerIndex.LinearButton
              btnLabel="Print"
              className="linear-btn-main"
              onClick={() => handlePrint()}
            ></ContainerIndex.LinearButton>
            <ContainerIndex.LinearButton
              btnLabel="Excel"
              className="linear-btn-main"
              onClick={() => exportToExcel()}
            ></ContainerIndex.LinearButton> */}
          </Index.Box>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
