import type from "./type";
import {
  loginApiHandler,
  forgetPasswordApiHandler,
  changePasswordApiHandler,
  companyListApiHandler,
  editAdminProfileApiHandler,
  addCompanyApiHandler,
  editCompanyApiHandler,
  viewCompanyListApiHandler,
  countryListApiHandler,
  stateListApiHandler,
  officeCityListApiHandler,
  factoryCityListApiHandler,
  companyApproveRejectApiHandler,
  addAreaMasterApiHandler,
  areaMasterListApiHandler,
  areaMasterApproveRejectApiHandler,
  areaMasterDeleteApiHandler,
  narrationMasterDeleteApiHandler,
  addProcessMasterApiHandler,
  processMasterListApiHandler,
  processMasterApproveRejectApiHandler,
  processMasterDeleteApiHandler,
  addGodwonMasterApiHandler,
  godwonMasterListApiHandler,
  godwonMasterApproveRejectApiHandler,
  godwonMasterDeleteApiHandler,
  addSizeMasterApiHandler,
  sizeMasterListApiHandler,
  sizeMasterApproveRejectApiHandler,
  sizeMasterDeleteApiHandler,
  addColourMasterApiHandler,
  colourMasterListApiHandler,
  colourMasterApproveRejectApiHandler,
  colourMasterDeleteApiHandler,
  addUnitMasterApiHandler,
  unitMasterListApiHandler,
  unitMasterApproveRejectApiHandler,
  UnitMasterDeleteApiHandler,
  addStateMasterApiHandler,
  stateMasterListApiHandler,
  addNarrationMasterApiHandler,
  narrationMasterListApiHandler,
  narrationMasterApproveRejectApiHandler,
  addSetMasterApiHandler,
  setMasterListApiHandler,
  getAllColourMasterApiHandler,
  setMasterApproveRejectApiHandler,
  setMasterDeleteApiHandler,
  companyTypeListApiHandler,
  industryTypeListApiHandler,
  getCompanyProfileApiHandler,
  allCityListApiHandler,
  addCountryMasterApiHandler,
  addCityMasterApiHandler,
  cityMasterListApiHandler,
  getAdminCountryListApiHandler,
  countryApproveRejectApiHandler,
  countryMasterDeleteApiHandler,
  stateApproveRejectApiHandler,
  stateMasterDeleteApiHandler,
  cityApproveRejectApiHandler,
  cityStateListApiHandler,
  cityMasterDeleteApiHandler,
  productGroupMasterApiHandler,
  addProductGroupMasterApiHandler,
  productGroupMasterApproveRejectApiHandler,
  DeleteProductGroupMasterApiHandler,
  addProductCategoryMasterApiHandler,
  productCategoryMasterListApiHandler,
  productCategoryMasterApproveRejectApiHandler,
  productCategoryMasterDeleteApiHandler,
  getHSNMasterListApiHandler,
  addHSNMasterApiHandler,
  HSNMasterApproveRejectApiHandler,
  HSNMasterDeleteApiHandler,
  addProductMasterApiHandler,
  ApproveRejectProductMasterApiHandler,
  deleteProductMasterApiHandler,
  getProductMasterApiHandler,
  zoneMasterListApiHandler,
  addZoneMasterApiHandler,
  zoneApproveRejectApiHandler,
  zoneMasterDeleteApiHandler,
  getOpeningStockMasterApiHandler,
  addOpeningStockMasterApiHandler,
  approveRejectOpeningStockMasterApiHandler,
  deleteOpeningStockMasterApiHandler,
  addFinishStockApiHandler,
  FinishStockMasterListApiHandler,
  FinishStockMasterDeleteApiHandler,
  approveRejectFinishStockMasterApiHandler,
  taxCategoryMasterListApiHandler,
  taxCategoryMasterDeleteApiHandler,
  approveRejectTaxCategoryMasterApiHandler,
  addTaxCategoryMasterApiHandler,
  getActiveTaxMasterListApiHandler,
  allTaxMasterListApiHandler,
  taxMasterApproveRejectApiHandler,
  addTaxMasterApiHandler,
  taxMasterDeleteApiHandler,
  getTaxTypeListApiHandler,
  getCalculationsTypeListApiHandler,
  itemSizePriceApiHandler,
  itemSizePriceListApiHandler,
  getActiveHeadGroupMasterListApiHandler,
  getActiveAccountGroupMasterListApiHandler,
  addAccountGroupMasterApiHandler,
  approveRejectAccountGroupMasterApiHandler,
  accountGroupMasterDeleteApiHandler,
  approveRejectitemSizePriceListApiHandler,
  SizePriceListDeleteApiHandler,
  checkDuplicateDataApiHandler,
  departmentMasterListApiHandler,
  departmentMasterApproveRejectApiHandler,
  departmentMasterDeleteApiHandler,
  addDepartmentMasterApiHandler,
  getCostGroupMasterListApiHandler,
  costGroupMasterApproveRejectApiHandler,
  costGroupMasterDeleteApiHandler,
  addCostGroupMasterApiHandler,
  getFixedAssetMasterListApiHandler,
  fixedAssetMasterApproveRejectApiHandler,
  fixedAssetMasterDeleteApiHandler,
  addFixedAssetsMasterApiHandler,
  getAccountMasterListApiHandler,
  accountMasterApproveRejectApiHandler,
  addAccountMasterApiHandler,
  accountMasterDeleteApiHandler,
  multipleDataAListApiHandler,
  bindDataAListApiHandler,
  getOpeningBalanceListApiHandler,
  openingBalanceActiveInactiveApiHandler,
  openingBalanceDeleteApiHandler,
  addOpeningBalanceApiHandler,
  addOutstandingVoucherApiHandler,
  outstandingVoucherListApiHandler,
  unclearedChequeListApiHandler,
  addUnclearedChequeApiHandler,
  resendVerificationEmailApiHandler,
  getSalesPurchaseListApiHandler,
  salesPrerequisiteApiHandler,
  setMasterWithSizeApiHandler,
  getSalesUsersApiHandler,
  createSalesOrderApiHandler,
  getSalesListApiHandler,
  getFixedAssetCompanyApiHandler,
  sizeMasterListWebviewApiHandler,
  whatsappSizeMasterListApiHandler,
} from "../../services/AdminIndex";

const callApi = (dispatch, token, fun, success, t, t2) => {
  import("../../services/AdminIndex").then((data) => {
    data[fun](token, t, t2).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type[success], payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  });
};

// Admin Login Function
export const AdminLoginAction = (params, navigate, domain) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_LOGIN_REQUEST });
    loginApiHandler(params).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ADMIN_LOGIN_SUCCESS,
          payload: { ...data, domain },
        });
        navigate("/dashboard/home");
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Admin Logout Function
export const AdminLogoutAction = (navigate, domain) => {
  return async function (dispatch) {
    try {
      dispatch({ type: type.ADMIN_LOGOUT_REQUEST });
      dispatch({ type: type.ADMIN_LOGOUT_SUCCESS });
      navigate(`${domain ? `/${domain}` : "/"}`);
    } catch (e) {
      dispatch({ type: type.ADMIN_ERROR_HANDLER });
    }
  };
};

// Admin Forgot Password Function
export const AdminForgotPassword = (params, navigate) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_FORGOTPASSWORD_REQUEST });
    forgetPasswordApiHandler(params).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_FORGOTPASSWORD_SUCCESS, payload: data });
        navigate("/");
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Admin Change Password Function
export const AdminChangePassword = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_CHANGE_PASSWORD_REQUEST });
    changePasswordApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_CHANGE_PASSWORD_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Company List Function
export const GetCompanyListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_COMPANY_LIST_REQUEST });
    companyListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_COMPANY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Company Function
export const AddCompanyAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_COMPANY_ADD_REQUEST });
    addCompanyApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_COMPANY_ADD_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "companyListApiHandler",
          "ADMIN_COMPANY_LIST_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Single Company Detail Function
export const GetSingleCompanyDetailAction = (company_Id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SINGLE_COMPANY_REQUEST });
    viewCompanyListApiHandler(company_Id).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SINGLE_COMPANY_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Edit Company Function
export const EditCompanyAction = (params, navigate, token, id, userType) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_COMPANY_EDIT_REQUEST });
    editCompanyApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_COMPANY_EDIT_SUCCESS, payload: data });
        if (userType === "admin") {
          // type admin then get all company list
          callApi(
            dispatch,
            token,
            "companyListApiHandler",
            "ADMIN_COMPANY_LIST_SUCCESS"
          );
        }
        if (id) {
          navigate("/dashboard/home");
        } else {
          navigate("/dashboard/client-list");
        }
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Company Type List Function
export const GetCompanyTypeList = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_COMPANY_TYPE_LIST_REQUEST });
    companyTypeListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_COMPANY_TYPE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Country List Function
export const GetCountryList = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.COUNTRY_LIST_REQUEST });
    countryListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.COUNTRY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All State List By Country Id Function
export const GetStateList = (countryId, token) => {
  return async function (dispatch) {
    dispatch({ type: type.STATE_LIST_REQUEST });
    stateListApiHandler(countryId, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.STATE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Office City List By State Id Function
export const GetOfficeCityList = (stateId, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OFFICE_CITY_LIST_REQUEST });
    officeCityListApiHandler(stateId, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.OFFICE_CITY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All City List By State Id Function
export const GetFactoryCityList = (stateId, token) => {
  return async function (dispatch) {
    dispatch({ type: type.FACTORY_CITY_LIST_REQUEST });
    factoryCityListApiHandler(stateId, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.FACTORY_CITY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Company status active or deactive function
export const CompanyActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.COMPANY_STATUS_REQUEST });
    companyApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.COMPANY_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "companyListApiHandler",
          "ADMIN_COMPANY_LIST_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Area Master Function
export const AddAreaMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_AREA_MASTER_REQUEST });
    addAreaMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_AREA_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "areaMasterListApiHandler",
          "GET_AREA_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_AREA_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "areaMasterListApiHandler",
          "GET_AREA_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Area Master Function
export const GetAreaMasterListAction = (token, id, all) => {
  return async function (dispatch) {
    //dispatch({ type: type.GET_AREA_MASTER_REQUEST });
    areaMasterListApiHandler(token, id, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_AREA_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Area Master active/inactive Function
export const AreaActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.AREA_MASTER_APPROVE_REQUEST });
    areaMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.AREA_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "areaMasterListApiHandler",
          "GET_AREA_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Area master function
export const DeleteAreaMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.AREA_MASTER_DELETE_REQUEST });
    areaMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.AREA_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "areaMasterListApiHandler",
          "GET_AREA_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Process Master Function
export const AddProcessMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_PROCESS_MASTER_REQUEST });
    addProcessMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_PROCESS_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "processMasterListApiHandler",
          "GET_PROCESS_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_PROCESS_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "processMasterListApiHandler",
          "GET_PROCESS_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Process Master Function
export const GetProcessMasterListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_PROCESS_MASTER_REQUEST });
    processMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_PROCESS_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Process Master active/inactive Function
export const AdminProcessMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PROCESS_MASTER_APPROVE_REQUEST });
    processMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PROCESS_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "processMasterListApiHandler",
          "GET_PROCESS_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Process master function
export const DeleteProcessMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_PROCESS_MASTER_REQUEST });
    processMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.DELETE_PROCESS_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "processMasterListApiHandler",
          "GET_PROCESS_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Godwon Master Function
export const AddGodwonMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_GODWON_MASTER_REQUEST });
    addGodwonMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_GODWON_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "godwonMasterListApiHandler",
          "GET_GODWON_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_GODWON_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "godwonMasterListApiHandler",
          "GET_GODWON_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Godwon Master Function
export const GetGodwonMasterListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_GODWON_MASTER_REQUEST });
    godwonMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_GODWON_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Godwon Master active/inactive Function
export const AdminGodwonApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.GODWON_MASTER_APPROVE_REQUEST });
    godwonMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GODWON_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "godwonMasterListApiHandler",
          "GET_GODWON_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Godwon master function
export const AdminDeleteGodwonMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_GODWON_MASTER_REQUEST });
    godwonMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.DELETE_GODWON_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "godwonMasterListApiHandler",
          "GET_GODWON_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Size Master Function
export const AddSizeMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_SIZE_MASTER_REQUEST });
    addSizeMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_SIZE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "sizeMasterListApiHandler",
          "GET_SIZE_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_SIZE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "sizeMasterListApiHandler",
          "GET_SIZE_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Size Master Function
export const GetSizeMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SIZE_MASTER_REQUEST });
    sizeMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SIZE_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
export const GetwhatsappSizeMasterListAction = (companyId, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SIZE_MASTER_REQUEST });
    whatsappSizeMasterListApiHandler(companyId, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SIZE_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Size Master Function
export const GetSizeMasterListWebviewAction = (all, companyId) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SIZE_MASTER_REQUEST });
    sizeMasterListWebviewApiHandler(all, companyId).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SIZE_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Size master status active or deactive function
export const AdminSizeMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_SIZE_MASTER_STATUS_REQUEST });
    sizeMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ADMIN_SIZE_MASTER_STATUS_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "sizeMasterListApiHandler",
          "GET_SIZE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Size master function
export const DeleteSizeMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_SIZE_MASTER_REQUEST });
    sizeMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.DELETE_SIZE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "sizeMasterListApiHandler",
          "GET_SIZE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Colour Master Function
export const AddColourMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_COLOUR_MASTER_REQUEST });
    addColourMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_COLOUR_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "colourMasterListApiHandler",
          "GET_COLOUR_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_COLOUR_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "colourMasterListApiHandler",
          "GET_COLOUR_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Colour Master Function
export const GetColourMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_COLOUR_MASTER_REQUEST });
    colourMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_COLOUR_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Colour master status active or deactive function
export const ColourMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.COLOUR_MASTER_APPROVE_REQUEST });
    colourMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.COLOUR_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "colourMasterListApiHandler",
          "GET_COLOUR_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Colour master function
export const DeleteColourMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_COLOUR_MASTER_REQUEST });
    colourMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.DELETE_COLOUR_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "colourMasterListApiHandler",
          "GET_COLOUR_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Unit Master Function
export const AddUnitMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_UNIT_MASTER_REQUEST });
    addUnitMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_UNIT_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unitMasterListApiHandler",
          "GET_UNIT_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_UNIT_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unitMasterListApiHandler",
          "GET_UNIT_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Unit Master Function
export const GetUnitMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_UNIT_MASTER_REQUEST });
    unitMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_UNIT_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Unit master status active or deactive function
export const AdminUnitMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_UNIT_STATUS_REQUEST });
    unitMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_UNIT_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unitMasterListApiHandler",
          "GET_UNIT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Unit master function
export const AdminDeleteUnitMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.UNIT_MASTER_DELETE_REQUEST });
    UnitMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.UNIT_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unitMasterListApiHandler",
          "GET_UNIT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Narration Master Function
export const AddNarrationMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_NARRATION_MASTER_REQUEST });
    addNarrationMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_NARRATION_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "narrationMasterListApiHandler",
          "GET_NARRATION_MASTER_SUCCESS"
        );
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_NARRATION_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "narrationMasterListApiHandler",
          "GET_NARRATION_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Narration Master Function
export const GetNarrationMasterListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_NARRATION_MASTER_REQUEST });
    narrationMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_NARRATION_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Narration Master active/inactive Function
export const AdminNarrationApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.NARRATION_MASTER_APPROVE_REQUEST });
    narrationMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.NARRATION_MASTER_APPROVE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "narrationMasterListApiHandler",
          "GET_NARRATION_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Narration master function
export const AdminDeleteNarrationMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.NARRATION_MASTER_DELETE_REQUEST });
    narrationMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.NARRATION_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "narrationMasterListApiHandler",
          "GET_NARRATION_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Set Master Function
export const AddSetMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_SET_MASTER_REQUEST });
    addSetMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_SET_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "setMasterListApiHandler",
          "GET_SET_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_SET_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "setMasterListApiHandler",
          "GET_SET_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Set Master Function
export const GetSetMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SET_MASTER_REQUEST });
    setMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SET_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Active Colour Masters Function
export const GetAllColourMasterAction = (token, id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ALL_COLOUR_MASTER_REQUEST });
    getAllColourMasterApiHandler(token, id).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ALL_COLOUR_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Set Master active/inactive Function
export const AdminSetMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.SET_MASTER_APPROVE_REQUEST });
    setMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.SET_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "setMasterListApiHandler",
          "GET_SET_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Set master function
export const DeleteSetMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.SET_MASTER_DELETE_REQUEST });
    setMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.SET_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "setMasterListApiHandler",
          "GET_SET_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Industry Type List Function
export const GetIndustrTypeList = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_INDUSTRY_TYPE_LIST_REQUEST });
    industryTypeListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_INDUSTRY_TYPE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Edit Admin Profile Function
export const EditAdminProfileAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.EDIT_ADMIN_PROFILE_REQUEST });
    editAdminProfileApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.EDIT_ADMIN_PROFILE_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Admin Profile List Function
export const GetAdminProfileListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ADMIN_PROFILE_REQUEST });
    getCompanyProfileApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ADMIN_PROFILE_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All City List Function
export const GetAllCityList = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.ALL_CITY_LIST_REQUEST });
    allCityListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ALL_CITY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Admin Country List Function
export const GetAdminCountryListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ADMINA_COUNTRY_LIST_REQUEST });
    getAdminCountryListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ADMINA_COUNTRY_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Country Master Function
export const AddCountryMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_COUNTRY_MASTER_REQUEST });
    addCountryMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_COUNTRY_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAdminCountryListApiHandler",
          "GET_ADMINA_COUNTRY_LIST_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_COUNTRY_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAdminCountryListApiHandler",
          "GET_ADMINA_COUNTRY_LIST_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Country master status active or deactive function
export const AdminCountryActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_COUNTRY_STATUS_REQUEST });
    countryApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ADMIN_COUNTRY_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAdminCountryListApiHandler",
          "GET_ADMINA_COUNTRY_LIST_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Country master function
export const AdminDeleteCountryMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.COUNTRY_MASTER_DELETE_REQUEST });
    countryMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.COUNTRY_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAdminCountryListApiHandler",
          "GET_ADMINA_COUNTRY_LIST_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add State Master Function
export const AddStateMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_STATE_MASTER_REQUEST });
    addStateMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_STATE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "stateMasterListApiHandler",
          "GET_STATE_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_STATE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "stateMasterListApiHandler",
          "GET_STATE_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get State Master Function
export const GetStateMasterListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_STATE_MASTER_REQUEST });
    stateMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_STATE_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// State master status active or deactive function
export const AdminStateMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_STATE_MASTER_STATUS_REQUEST });
    stateApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ADMIN_STATE_MASTER_STATUS_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "stateMasterListApiHandler",
          "GET_STATE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete State master function
export const AdminDeleteStateMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_STATE_MASTER_DELETE_REQUEST });
    stateMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ADMIN_STATE_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "stateMasterListApiHandler",
          "GET_STATE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add City Master Function
export const AddCityMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_CITY_MASTER_REQUEST });
    addCityMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_CITY_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "cityMasterListApiHandler",
          "GET_CITY_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_CITY_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "cityMasterListApiHandler",
          "GET_CITY_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get City Master Function
export const GetCityMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_CITY_MASTER_REQUEST });
    cityMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_CITY_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// City master status active or deactive function
export const AdminCityActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADMIN_CITY_MASTER_STATUS_REQUEST });
    cityApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ADMIN_CITY_MASTER_STATUS_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "cityMasterListApiHandler",
          "GET_CITY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All State List Function
export const GetAllCityStateListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.CITY_STATE_LIST_REQUEST });
    cityStateListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.CITY_STATE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete City master function
export const AdminDeleteCityMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.CITY_MASTER_DELETE_REQUEST });
    cityMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.CITY_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "cityMasterListApiHandler",
          "GET_CITY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

//get Product Group Master function
export const ProductGroupMasterAction = (token, id, all) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_GROUP_MASTER_REQUEST });
    productGroupMasterApiHandler(token, id, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PRODUCT_GROUP_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Product Group Master
export const AddProductGroupMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_PRODUCT_GROUP_MASTER_REQUEST });
    addProductGroupMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({
          type: type.ADD_PRODUCT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productGroupMasterApiHandler",
          "PRODUCT_GROUP_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_PRODUCT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productGroupMasterApiHandler",
          "PRODUCT_GROUP_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Product Group master status active or deactive function
export const ProductGroupMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_GROUP_STATUS_REQUEST });
    productGroupMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PRODUCT_GROUP_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "productGroupMasterApiHandler",
          "PRODUCT_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Product Group Master
export const DeleteProductGroupMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_PRODUCT_GROUP_MASTER_REQUEST });
    DeleteProductGroupMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.DELETE_PRODUCT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productGroupMasterApiHandler",
          "PRODUCT_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Product Category Master Function
export const AddProductCategoryMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_PRODUCT_CATEGORY_MASTER_REQUEST });
    addProductCategoryMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({
          type: type.ADD_PRODUCT_CATEGORY_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productCategoryMasterListApiHandler",
          "GET_PRODUCT_CATEGORY_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_PRODUCT_CATEGORY_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productCategoryMasterListApiHandler",
          "GET_PRODUCT_CATEGORY_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Product Category Master Function
export const GetProductCategoryMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_PRODUCT_CATEGORY_MASTER_REQUEST });
    productCategoryMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_PRODUCT_CATEGORY_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Product Category master status active or deactive function
export const ProductCategoryMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_CATEGORY_STATUS_REQUEST });
    productCategoryMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PRODUCT_CATEGORY_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "productCategoryMasterListApiHandler",
          "GET_PRODUCT_CATEGORY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Product Category master function
export const DeleteProductCategoryMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_CATEGORY_MASTER_DELETE_REQUEST });
    productCategoryMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.PRODUCT_CATEGORY_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "productCategoryMasterListApiHandler",
          "GET_PRODUCT_CATEGORY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get HSN Category Master Function
export const GetHSNMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_HSN_MASTER_REQUEST });
    getHSNMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_HSN_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add HSN Master Function
export const AddHSNMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_HSN_MASTER_REQUEST });
    addHSNMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_HSN_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getHSNMasterListApiHandler",
          "GET_HSN_MASTER_SUCCESS"
        );
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_HSN_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getHSNMasterListApiHandler",
          "GET_HSN_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// HSN master status active or deactive function
export const HSNMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.HSN_STATUS_REQUEST });
    HSNMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.HSN_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getHSNMasterListApiHandler",
          "GET_HSN_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete HSN master function
export const DeleteHSNMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.HSN_MASTER_DELETE_REQUEST });
    HSNMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.HSN_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getHSNMasterListApiHandler",
          "GET_HSN_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Product Category Master Function
export const GetProductMasterListAction = (token, id, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_PRODUCT_MASTER_REQUEST });
    getProductMasterApiHandler(token, id, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_PRODUCT_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Product Category Master Function
export const GetProductMasterSingleAction = (token, id, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SINGLE_PRODUCT_MASTER_REQUEST });
    getProductMasterApiHandler(token, id, all).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_SINGLE_PRODUCT_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Product Master Function
export const AddProductMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_PRODUCT_MASTER_REQUEST });
    addProductMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_PRODUCT_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getProductMasterApiHandler",
          "GET_PRODUCT_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_PRODUCT_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getProductMasterApiHandler",
          "GET_PRODUCT_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
// Add Zone Master Function
export const AddZoneMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_ZONE_MASTER_REQUEST });
    addZoneMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_ZONE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "zoneMasterListApiHandler",
          "GET_ZONE_MASTER_SUCCESS"
        );
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_ZONE_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "zoneMasterListApiHandler",
          "GET_ZONE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Product Product status active or deactive function
export const ProductMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_STATUS_REQUEST });
    ApproveRejectProductMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PRODUCT_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getProductMasterApiHandler",
          "GET_PRODUCT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
// Get Zone Master Function
export const GetZoneMasterListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ZONE_MASTER_REQUEST });
    zoneMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ZONE_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Product master function
export const DeleteProductMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.PRODUCT_MASTER_DELETE_REQUEST });
    deleteProductMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.PRODUCT_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getProductMasterApiHandler",
          "GET_PRODUCT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
// Zone master status active or deactive function
export const ZoneActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ZONE_MASTER_STATUS_REQUEST });
    zoneApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ZONE_MASTER_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "zoneMasterListApiHandler",
          "GET_ZONE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Zone master function
export const DeleteZoneMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ZONE_MASTER_DELETE_REQUEST });
    zoneMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ZONE_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "zoneMasterListApiHandler",
          "GET_ZONE_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Opening Stock Master Function
export const GetOpeningStockMasterListAction = (token, id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_OPENING_STOCK_MASTER_REQUEST });
    getOpeningStockMasterApiHandler(token, id).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_OPENING_STOCK_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Opening Stock Master Function
export const AddOpeningStockMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_OPENING_STOCK_MASTER_REQUEST });
    addOpeningStockMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({
          type: type.ADD_OPENING_STOCK_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getOpeningStockMasterApiHandler",
          "GET_OPENING_STOCK_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_OPENING_STOCK_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getOpeningStockMasterApiHandler",
          "GET_OPENING_STOCK_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add FinishStock Function
export const AddFinishStockMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_FINISH_STOCK_REQUEST });
    addFinishStockApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_FINISH_STOCK_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "FinishStockMasterListApiHandler",
          "GET_FINISH_STOCK_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_FINISH_STOCK_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "FinishStockMasterListApiHandler",
          "GET_FINISH_STOCK_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Opening Stock status active or deactive function
export const OpeningStockMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OPENING_STOCK_STATUS_REQUEST });
    approveRejectOpeningStockMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.OPENING_STOCK_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getOpeningStockMasterApiHandler",
          "GET_OPENING_STOCK_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Stock master function
export const DeleteOpeningStockMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OPENING_STOCK_MASTER_DELETE_REQUEST });
    deleteOpeningStockMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.OPENING_STOCK_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getOpeningStockMasterApiHandler",
          "GET_OPENING_STOCK_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get finish stock Function
export const GetFinishStockListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_FINISH_STOCK_REQUEST });
    FinishStockMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_FINISH_STOCK_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete finish stock function
export const DeleteFinishStockAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.FINISH_STOCK_DELETE_REQUEST });
    FinishStockMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.FINISH_STOCK_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "FinishStockMasterListApiHandler",
          "GET_FINISH_STOCK_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Finish Stock status active or deactive function
export const FinishStockMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OPENING_STOCK_STATUS_REQUEST });
    approveRejectFinishStockMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.OPENING_STOCK_STATUS_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "FinishStockMasterListApiHandler",
          "GET_FINISH_STOCK_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Tax Category master Function
export const GetTaxCategoryMasterListAction = (token, id, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_TAX_CATEGORY_MASTER_REQUEST });
    taxCategoryMasterListApiHandler(token, id, all).then((data) => {
      if (data.status === 200) {
        if (id) {
          // Id then single store get
          dispatch({
            type: type.GET_SINGLE_TAX_CATEGORY_MASTER_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: type.GET_TAX_CATEGORY_MASTER_SUCCESS,
            payload: data,
          });
        }
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Tax Category master function
export const DeleteTaxCategoryMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.TAX_CATEGORY_MASTER_DELETE_REQUEST });
    taxCategoryMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.TAX_CATEGORY_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "taxCategoryMasterListApiHandler",
          "GET_TAX_CATEGORY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Tax Category master status active or deactive function
export const TaxCategoryMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.TAX_CATEGORY_MASTER_STATUS_REQUEST });
    approveRejectTaxCategoryMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.TAX_CATEGORY_MASTER_STATUS_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "taxCategoryMasterListApiHandler",
          "GET_TAX_CATEGORY_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Tax Category master Function
export const AddTaxCategoryMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_TAX_CATEGORY_MASTER_REQUEST });
    addTaxCategoryMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_TAX_CATEGORY_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "taxCategoryMasterListApiHandler",
          "GET_TAX_CATEGORY_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_TAX_CATEGORY_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "taxCategoryMasterListApiHandler",
          "GET_TAX_CATEGORY_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Active Tax Master Function
export const GetAllTaxMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ACTIVE_TAX_MASTER_REQUEST });
    getActiveTaxMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ACTIVE_TAX_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Tax Master Function For Crud
export const GetTaxMasterAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_TAX_MASTER_REQUEST });
    allTaxMasterListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_TAX_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Tax Master active/inactive Function
export const TaxMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.TAX_MASTER_APPROVE_REQUEST });
    taxMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.TAX_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "allTaxMasterListApiHandler",
          "GET_TAX_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Tax Master function
export const DeleteTaxMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_TAX_MASTER_REQUEST });
    taxMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.DELETE_TAX_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "allTaxMasterListApiHandler",
          "GET_TAX_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Tax Master Function
export const AddTaxMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_TAX_MASTER_REQUEST });
    addTaxMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_TAX_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "allTaxMasterListApiHandler",
          "GET_TAX_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_TAX_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "allTaxMasterListApiHandler",
          "GET_TAX_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Tax Type List Function
export const GetAllTaxTypeListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_TAX_TYPE_LIST_REQUEST });
    getTaxTypeListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_TAX_TYPE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Calculation Type List Function
export const GetCalculationsTypeListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_CALCULATION_TYPE_LIST_REQUEST });
    getCalculationsTypeListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_CALCULATION_TYPE_LIST_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add ItemSizePrice Function
export const ItemSizePriceAction = (params, token, t, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_ITEM_SIZE_LIST_REQUEST });
    itemSizePriceApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_ITEM_SIZE_LIST_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "itemSizePriceListApiHandler",
          "GET_ITEM_SIZE_LIST_SUCCESS",
          t
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_ITEM_SIZE_LIST_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "itemSizePriceListApiHandler",
          "GET_ITEM_SIZE_LIST_SUCCESS",
          t
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get ItemSizePriceAction Function
export const GetItemSizePriceAction = (token, t) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ITEM_SIZE_LIST_REQUEST });
    itemSizePriceListApiHandler(token, t).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ITEM_SIZE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get GetSingleItemSizePriceAction Function
export const GetSingleItemSizePriceAction = (token, id, editId) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ITEM_SIZE_LIST_REQUEST });
    itemSizePriceListApiHandler(token, id, editId).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_SINGLE_ITEM_SIZE_LIST_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// ItemSizePrice status active or deactive function
export const ItemSizePriceActiveInactiveAction = (params, token, t) => {
  return async function (dispatch) {
    dispatch({ type: type.ITEM_SIZE_LIST_APPROVE_REQUEST });
    approveRejectitemSizePriceListApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ITEM_SIZE_LIST_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "itemSizePriceListApiHandler",
          "GET_ITEM_SIZE_LIST_SUCCESS",
          t
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Account Group Master
export const GetAccountGroupMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ACCOUNT_GROUP_MASTER_REQUEST });
    getActiveAccountGroupMasterListApiHandler(token, "", all).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_ACCOUNT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
// Delete ItemSizePrice function
export const DeleteItemSizePriceAction = (params, token, t) => {
  return async function (dispatch) {
    dispatch({ type: type.ITEM_SIZE_LIST_DELETE_REQUEST });
    SizePriceListDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ITEM_SIZE_LIST_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "itemSizePriceListApiHandler",
          "GET_ITEM_SIZE_LIST_SUCCESS",
          t
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Account Group Master Function
export const AddAccountGroupMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_ACCOUNT_GROUP_MASTER_REQUEST });
    addAccountGroupMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({
          type: type.ADD_ACCOUNT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getActiveAccountGroupMasterListApiHandler",
          "GET_ACCOUNT_GROUP_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_ACCOUNT_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getActiveAccountGroupMasterListApiHandler",
          "GET_ACCOUNT_GROUP_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Active Head Group Master
export const GetActiveHeadGroupMasterAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ACTIVE_HEAD_GROUP_MASTER_REQUEST });
    getActiveHeadGroupMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_ACTIVE_HEAD_GROUP_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Account Group Master status active or deactive function
export const AccountGroupMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ACCOUNT_GROUP_MASTER_STATUS_REQUEST });
    approveRejectAccountGroupMasterApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ACCOUNT_GROUP_MASTER_STATUS_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getActiveAccountGroupMasterListApiHandler",
          "GET_ACCOUNT_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Account Group Master function
export const DeleteAccountGroupMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ACCOUNT_GROUP_MASTER_DELETE_REQUEST });
    accountGroupMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ACCOUNT_GROUP_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getActiveAccountGroupMasterListApiHandler",
          "GET_ACCOUNT_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Duplicate GST No. Function
export const CheckDuplicateDataAction = (typeName, value, token) => {
  return async function (dispatch) {
    dispatch({ type: type.CHECK_DUPLICATE_COMPANY_DATA_REQUEST });
    checkDuplicateDataApiHandler(typeName, value, token).then((data) => {
      if (data.status === 200) {
        if (typeName == "gst") {
          dispatch({
            type: type.CHECK_DUPLICATE_GST_COMPANY_DATA_SUCCESS,
            payload: data,
          });
        } else if (typeName == "email") {
          dispatch({
            type: type.CHECK_DUPLICATE_EMAIL_COMPANY_DATA_SUCCESS,
            payload: data,
          });
        }
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Department Master Function
export const GetDepartmentMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_DEPARTMENT_MASTER_REQUEST });
    departmentMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_DEPARTMENT_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Department Master Function
export const AddDepartmentMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_DEPARTMENT_MASTER_REQUEST });
    addDepartmentMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_DEPARTMENT_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "departmentMasterListApiHandler",
          "GET_DEPARTMENT_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_DEPARTMENT_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "departmentMasterListApiHandler",
          "GET_DEPARTMENT_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Department Master active/inactive Function
export const DepartmentMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DEPARTMENT_MASTER_APPROVE_REQUEST });
    departmentMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.DEPARTMENT_MASTER_APPROVE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "departmentMasterListApiHandler",
          "GET_DEPARTMENT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Department master function
export const DeleteDepartmentMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.DELETE_DEPARTMENT_MASTER_REQUEST });
    departmentMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.DELETE_DEPARTMENT_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "departmentMasterListApiHandler",
          "GET_DEPARTMENT_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Cost Group Master Function
export const GetCostGroupMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_COST_GROUP_MASTER_REQUEST });
    getCostGroupMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_COST_GROUP_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Active Cost Group List Function
export const ActiveCostGroupMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.ACTIVE_COST_GROUP_MASTER_REQUEST });
    getCostGroupMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.ACTIVE_COST_GROUP_MASTER_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Cost Group Master Function
export const AddCostGroupMasterAction = (params, token, close) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_COST_GROUP_MASTER_REQUEST });
    addCostGroupMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_COST_GROUP_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getCostGroupMasterListApiHandler",
          "GET_COST_GROUP_MASTER_SUCCESS"
        );
        close();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_COST_GROUP_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getCostGroupMasterListApiHandler",
          "GET_COST_GROUP_MASTER_SUCCESS"
        );
        close();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Cost Group master status active or deactive function
export const CostGroupMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.COST_GROUP_APPROVE_REQUEST });
    costGroupMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.COST_GROUP_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getCostGroupMasterListApiHandler",
          "GET_COST_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Group master master function
export const DeleteCostGroupMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.COST_GROUP_MASTER_DELETE_REQUEST });
    costGroupMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.COST_GROUP_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getCostGroupMasterListApiHandler",
          "GET_COST_GROUP_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Fixed Assets Master Function
export const GetFixedAssetMasterListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_FIXED_ASSET_MASTER_REQUEST });
    getFixedAssetMasterListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_FIXED_ASSET_MASTER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Fixed Assets master status active or deactive function
export const FixedAssetMasterApproveReject = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.FIXED_ASSET_APPROVE_REQUEST });
    fixedAssetMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.FIXED_ASSET_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getFixedAssetMasterListApiHandler",
          "GET_FIXED_ASSET_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Fixed Assets master function
export const DeleteFixedAssetMaster = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.FIXED_ASSET_MASTER_DELETE_REQUEST });
    fixedAssetMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.FIXED_ASSET_MASTER_DELETE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getFixedAssetMasterListApiHandler",
          "GET_FIXED_ASSET_MASTER_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Fixed Assets Master Function
export const AddFixedAssetsMasterAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_FIXED_ASSET_MASTER_REQUEST });
    addFixedAssetsMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_FIXED_ASSET_MASTER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getFixedAssetMasterListApiHandler",
          "GET_FIXED_ASSET_MASTER_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_FIXED_ASSET_MASTER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getFixedAssetMasterListApiHandler",
          "GET_FIXED_ASSET_MASTER_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Account Master List Function
export const GetAccountMasterList = (token, params, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_ACCOUNT_MASTER_LIST_REQUEST });
    getAccountMasterListApiHandler(token, params, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_ACCOUNT_MASTER_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Account Master status active or deactive function
export const AccountMasterActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ACCOUNT_MASTER_APPROVE_REQUEST });
    accountMasterApproveRejectApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ACCOUNT_MASTER_APPROVE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAccountMasterListApiHandler",
          "GET_ACCOUNT_MASTER_LIST_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Account Master Function
export const AddAccountMasterPageAction = (params, navigate, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_ACCOUNT_MASTER_REQUEST });
    addAccountMasterApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_ACCOUNT_MASTER_SUCCESS, payload: data });
        navigate("/dashboard/account-master");
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_ACCOUNT_MASTER_SUCCESS, payload: data });
        navigate("/dashboard/account-master");
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Account master function
export const DeleteAccountMasterAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.ACCOUNT_MASTER_DELETE_REQUEST });
    accountMasterDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.ACCOUNT_MASTER_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getAccountMasterListApiHandler",
          "GET_ACCOUNT_MASTER_LIST_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get multipleDataAccLedger Function
export const multipleDataAccLedger = (token, id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_MULTIPLE_DATA_REQUEST });
    multipleDataAListApiHandler(token, id).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_MULTIPLE_DATA_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get bindDataAccLedger Function
export const bindDataAccLedger = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_BIND_DATA_REQUEST });
    bindDataAListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_BIND_DATA_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
// Get Opening Balance List Function
export const GetOpeningBalanceListAction = (token, all) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_OPENING_BALANCE_REQUEST });
    getOpeningBalanceListApiHandler(token, all).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_OPENING_BALANCE_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Opening Balance status active or Inactive function
export const OpeningBalanceActiveInactiveAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OPENING_BALANCE_ACTIVE_INACTIVE_REQUEST });
    openingBalanceActiveInactiveApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.OPENING_BALANCE_ACTIVE_INACTIVE_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "getOpeningBalanceListApiHandler",
          "GET_OPENING_BALANCE_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Opening Balance Function
export const AddOpeningBalanceAction = (params, token, handleClose) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_OPENING_BALANCE_REQUEST });
    addOpeningBalanceApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_OPENING_BALANCE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getOpeningBalanceListApiHandler",
          "GET_OPENING_BALANCE_SUCCESS"
        );
        handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_OPENING_BALANCE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getOpeningBalanceListApiHandler",
          "GET_OPENING_BALANCE_SUCCESS"
        );
        handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Delete Opening Balance function
export const DeleteOpeningBalanceAction = (params, token) => {
  return async function (dispatch) {
    dispatch({ type: type.OPENING_BALANCE_DELETE_REQUEST });
    openingBalanceDeleteApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.OPENING_BALANCE_DELETE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "getOpeningBalanceListApiHandler",
          "GET_OPENING_BALANCE_SUCCESS"
        );
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Outstanding Voucher Function
export const GetOutstandingVoucherListAction = (token, all, id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_OUTSTANDING_VOUCHER_REQUEST });
    outstandingVoucherListApiHandler(token, all, id).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_OUTSTANDING_VOUCHER_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Outstanding Voucher Function
export const AddOutstandingVoucherAction = (params, token, handleClose, id) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_OUTSTANDING_VOUCHER_REQUEST });
    addOutstandingVoucherApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_OUTSTANDING_VOUCHER_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "outstandingVoucherListApiHandler",
          "GET_OUTSTANDING_VOUCHER_SUCCESS",
          "",
          id
        );
        // handleClose();
      } else if (data.status === 200) {
        dispatch({
          type: type.UPDATE_OUTSTANDING_VOUCHER_SUCCESS,
          payload: data,
        });
        callApi(
          dispatch,
          token,
          "outstandingVoucherListApiHandler",
          "GET_OUTSTANDING_VOUCHER_SUCCESS",
          "",
          id
        );
        // handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get Uncleared Cheque Function
export const GetUnclearedChequeListAction = (token, all, id) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_UNCLEARED_CHEQUE_REQUEST });
    unclearedChequeListApiHandler(token, all, id).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_UNCLEARED_CHEQUE_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Add Uncleared Cheque Function
export const AddUnclearedChequeAction = (params, token, handleClose, id) => {
  return async function (dispatch) {
    dispatch({ type: type.ADD_UNCLEARED_CHEQUE_REQUEST });
    addUnclearedChequeApiHandler(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_UNCLEARED_CHEQUE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unclearedChequeListApiHandler",
          "GET_UNCLEARED_CHEQUE_SUCCESS",
          "",
          id
        );
        // handleClose();
      } else if (data.status === 200) {
        dispatch({ type: type.UPDATE_UNCLEARED_CHEQUE_SUCCESS, payload: data });
        callApi(
          dispatch,
          token,
          "unclearedChequeListApiHandler",
          "GET_UNCLEARED_CHEQUE_SUCCESS",
          "",
          id
        );
        // handleClose();
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Resend Verification Email Function
export const ResendVerificationEmailAction = (params, token, close) => {
  return async function (dispatch) {
    dispatch({ type: type.RESEND_VERIFICATION_EMAIL_REQUEST });
    resendVerificationEmailApiHandler(params, token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.RESEND_VERIFICATION_EMAIL_SUCCESS,
          payload: data,
        });
        // close()
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get All Sales & Purchase List In Tax-Master Function
export const GetSalesPurchaseListAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SALSE_PURCHASE_LIST_REQUEST });
    getSalesPurchaseListApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_SALSE_PURCHASE_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get data for add sales record
export const getSalesPrerequisite = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SALES_PREREQUISITE_DATA_REQUEST });
    salesPrerequisiteApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_SALES_PREREQUISITE_DATA_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get set master with size
export const getSetMasterWithSizeAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SET_MASTER_WITH_SIZE_REQUEST });
    setMasterWithSizeApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_SET_MASTER_WITH_SIZE_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get set master with size
export const getSalesUsersAction = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SALES_USERS_DROPDOWN_DATA_REQUEST });
    getSalesUsersApiHandler(token).then((data) => {
      if (data.status === 200) {
        dispatch({
          type: type.GET_SALES_USERS_DROPDOWN_DATA_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Create sale order
export const createSalesOrderAction = (token, data, navigate) => {
  return async function (dispatch) {
    dispatch({ type: type.CREATE_SALES_ORDER_REQUEST });
    createSalesOrderApiHandler(token, data).then((data) => {
      if (data.status === 201 || data.status === 200) {
        dispatch({ type: type.CREATE_SALES_ORDER_SUCCESS, payload: data });
        navigate("/dashboard/sales-order-list");
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get sales order
export const getSalesOrderAction = (
  token,
  all,
  id,
  selectedYearStart,
  selectedYearEnd
) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_SALES_LIST_REQUEST });
    getSalesListApiHandler(
      token,
      all,
      id,
      selectedYearStart,
      selectedYearEnd
    ).then((data) => {
      if (data.status === 201 || data.status === 200) {
        dispatch({ type: type.GET_SALES_LIST_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

// Get fixed assets company
export const getFixedAssetCompany = (token) => {
  return async function (dispatch) {
    dispatch({ type: type.GET_FIXED_ASSET_COMPANY_REQUEST });
    getFixedAssetCompanyApiHandler(token).then((data) => {
      if (data.status === 201 || data.status === 200) {
        dispatch({ type: type.GET_FIXED_ASSET_COMPANY_SUCCESS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
